<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="AddPayment" :documentTitle="updateMode ? (salesType == 'contracts' ? (Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title) : (salesType == 'localsales' ? Localsale__title : Epr__title)) : ''"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <PaymentAlert
                    :alert="$t('message.closeAlert')"
                    mode="add"
                    type="warning"
                    class="mt-3"
                    v-if="addMode && newPaymentUnsaved"
                ></PaymentAlert>
                <PaymentAlert
                    :alert="$t('message.closeAlert')"
                    mode="update"
                    type="warning"
                    class="mt-3"
                    v-if="updateMode && updatedPaymentUnsaved"
                ></PaymentAlert>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation>
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                            :value="[0,2,3]"
                            accordion
                            multiple
                            v-if="salesType == 'contracts'"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.contractAndPoDetails') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.contractNo') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            {{ Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title }}
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.purchaseOrderNo') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Contract__Purchaseorder__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.invoiceNo') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Contract__Invoice__invno }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Contract__Customer__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyerPaymentTerm') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Contract__Paymentstatement__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Contract__Supplier__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplierPaymentTerm') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Contract__PoPaymentstatement__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.etd') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ formatDate(Contract__etddate) }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.eta') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ formatDate(Contract__etadate) }}</v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="[1,2,5].includes(Contract__salestype_id)">
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.buyerPayments') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3 v-if="Payment__id == null">
                                        <v-flex xs12 lg12 md12 pl-5 py-1 class="align-center">
                                            <v-alert
                                                color="red"
                                                dense
                                                icon="warning"
                                                style="height: unset; margin-bottom: 0px;"
                                                text
                                            >{{ $t('message.paymentRecordNotFound') }}</v-alert>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row :class="Payment__id ? 'pt-3' : '' " v-if="Payment__id != null">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">
                                            {{ $t('message.dueDate') }}
                                        </v-flex>
                                        <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row justify-space-between">
                                            <div>{{ Payment__paymentdate != '' && Payment__paymentdate != null ? formatDate(Payment__paymentdate) : $t('message.na') }}</div>
                                            <v-btn
                                                :loading="loading.updateDueDate"
                                                small
                                                outlined
                                                @click="updateDueDates(salesType)"
                                            >{{ $t('message.updateDueDate') }}
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="Payment__id != null && Payment__dprequired == 1 && false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.dpPaid') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                :true-value="1"
                                                :value="1"
                                                class="mt-0 ml-0"
                                                dense
                                                hide-details
                                                v-model="Payment__is_dppaid"
                                                @change="updateDownPaymentPaidStatus('c', Payment__paymentstatement_id, $event)"
                                            ></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="Payment__id != null">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.fullyPaid') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                :true-value="1"
                                                :value="1"
                                                class="mt-0 ml-0"
                                                dense
                                                hide-details
                                                v-model="Payment__fully_paid"
                                                @change="updateFullyPaidStatus('c', Payment__paymentstatement_id, $event)"
                                            ></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row  v-if="Payment__id != null">
<!--                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center" v-if="false">{{ $t('message.currentPaymentTerm') }}</v-flex>-->
<!--                                        <v-flex xs6 lg3 md5 py-1 v-if="false">{{ getCurrentPaymentStatus(Payment__paymentterm_id) }}</v-flex>-->
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.currentPaymentStatus') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <div class="d-flex flex-row">
                                                <v-progress-circular
                                                    class="mr-2"
                                                    color="grey lighten-1"
                                                    indeterminate
                                                    size="20"
                                                    width="4"
                                                    v-if="loading.paymentTitle"
                                                ></v-progress-circular>
                                                <span>{{ Payment__title }}</span>
                                            </div>
                                            <v-autocomplete
                                                :items="paymentStatuses"
                                                :value="Payment__paymentterm_id"
                                                dense
                                                hide-details
                                                item-text="PaymentStatus.title"
                                                item-value="PaymentStatus.id"
                                                return-object
                                                solo
                                                @change="updatePaymentStatus('Payment__paymentterm_id',$event)"
                                                v-if="false"
                                            ></v-autocomplete>
                                            <v-autocomplete
                                                :items="paymentStatuses"
                                                :placeholder="$t('message.selectNewStatus')"
                                                dense
                                                hide-details
                                                item-text="PaymentStatus.title"
                                                item-value="PaymentStatus.id"
                                                return-object
                                                solo
                                                @change="updatePaymentStatus('Payment__paymentterm_id',$event)"
                                                v-if="false"
                                            ></v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <PaymentTransactionsList
                                        :update-mode="updateMode"
                                        payment-type="Payment"
                                        document-type="Contract"
                                        class="ml-2"
                                        @dp-paid-set="updateDownPaymentPaidStatusV2('c', Payment__paymentstatement_id, 1)"
                                        @dp-paid-unset="updateDownPaymentPaidStatusV2('c', Payment__paymentstatement_id, null)"
                                        v-if="Payment__id != null"
                                    ></PaymentTransactionsList>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="[1,3,5,6].includes(Contract__salestype_id)">
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.supplierPayments') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3 v-if="PoPayment__id == null">
                                        <v-flex xs12 lg12 md12 pl-5 py-1 class="align-center">
                                            <v-alert
                                                color="red"
                                                dense
                                                icon="warning"
                                                style="height: unset; margin-bottom: 0px;"
                                                text
                                            >{{ $t('message.poPaymentRecordNotFound') }}</v-alert>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row :class="PoPayment__id ? 'pt-3' : '' " v-if="PoPayment__id != null">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">
                                            {{ $t('message.dueDate') }}
                                        </v-flex>
                                        <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row justify-space-between">
                                            <div>{{ PoPayment__paymentdate != '' && PoPayment__paymentdate != null ? formatDate(PoPayment__paymentdate) : $t('message.na') }}</div>
                                            <v-btn
                                                :loading="loading.updateDueDate"
                                                small
                                                outlined
                                                @click="updateDueDates(salesType)"
                                            >{{ $t('message.updateDueDate') }}
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="PoPayment__id != null && PoPayment__dprequired == 1 && false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.dpPaid') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                :true-value="1"
                                                :value="1"
                                                class="mt-0 ml-0"
                                                dense
                                                hide-details="auto"
                                                v-model="PoPayment__is_dppaid"
                                                @change="updateDownPaymentPaidStatus('p', PoPayment__paymentstatement_id, $event)"
                                            ></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="PoPayment__id != null">
                                        <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.fullyPaid') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                :true-value="1"
                                                :value="1"
                                                class="mt-0 ml-0"
                                                dense
                                                hide-details="auto"
                                                v-model="PoPayment__fully_paid"
                                                @change="updateFullyPaidStatus('p', PoPayment__paymentstatement_id, $event)"
                                            ></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="PoPayment__id != null">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.currentPaymentStatus') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <div class="d-flex flex-row">
                                                <v-progress-circular
                                                    class="mr-2"
                                                    color="grey lighten-1"
                                                    indeterminate
                                                    size="20"
                                                    width="4"
                                                    v-if="loading.poPaymentTitle"
                                                ></v-progress-circular>
                                                <span>{{ PoPayment__title }}</span>
                                            </div>
                                            <v-autocomplete
                                                :items="paymentStatuses"
                                                :value="PoPayment__paymentterm_id"
                                                dense
                                                hide-details
                                                item-text="PaymentStatus.title"
                                                item-value="PaymentStatus.id"
                                                return-object
                                                solo
                                                @change="updatePaymentStatus('PoPayment__paymentterm_id',$event)"
                                                v-if="false"
                                            ></v-autocomplete>
                                            <v-autocomplete
                                                :items="paymentStatuses"
                                                :placeholder="$t('message.selectNewStatus')"
                                                dense
                                                hide-details
                                                item-text="PaymentStatus.title"
                                                item-value="PaymentStatus.id"
                                                return-object
                                                solo
                                                @change="updatePaymentStatus('PoPayment__paymentterm_id',$event)"
                                                v-if="false"
                                            ></v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <PaymentTransactionsList
                                        :update-mode="updateMode"
                                        payment-type="PoPayment"
                                        document-type="Contract"
                                        class="ml-2"
                                        v-if="PoPayment__id != null"
                                        @dp-paid-set="updateDownPaymentPaidStatusV2('p', PoPayment__paymentstatement_id, 1)"
                                        @dp-paid-unset="updateDownPaymentPaidStatusV2('p', PoPayment__paymentstatement_id, null)"
                                    ></PaymentTransactionsList>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_payment')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card elevation="0"class="pt-3">
                                        <v-tabs background-color="grey lighten-3">
                                            <v-tab key="email" href="#tab-email" @click="updateHistoryKey()"><v-icon small class="pr-3">send</v-icon>{{ $t('message.notifications') }}</v-tab>
                                            <v-tab key="audit_log" href="#tab-audit_log" @click="updateHistoryKey()"><v-icon small class="pr-3">list</v-icon>{{ $t('message.auditLog') }}</v-tab>
                                            <v-tab-item key="email" value="tab-email">
                                                <NotificationsLog asset="payments" :asset_id="Contract__id" :key="'nl' + historyKey"></NotificationsLog>
                                            </v-tab-item>
                                            <v-tab-item key="audit_log" value="tab-audit_log">
                                                <v-row no-gutters>
                                                    <v-col cols="12" class="pr-1" v-if="Payment__id != null">
                                                        <DocumentHistory asset="payments" :asset_id="Payment__id" :key="'dh' + historyKey"></DocumentHistory>
                                                    </v-col>
                                                    <v-col cols="12" class="pl-1" v-if="PoPayment__id != null">
                                                        <DocumentHistory asset="payments" :asset_id="PoPayment__id" :text-replace="[{'Payment':'PO Payment'}]" v-if="Contract__salestype_id != 2"  :key="'dhpo' + historyKey"></DocumentHistory>
                                                    </v-col>
                                                </v-row>
                                            </v-tab-item>
                                        </v-tabs>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-expansion-panels
                            :value="[0,1]"
                            accordion
                            multiple
                            v-if="salesType == 'localsales'"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.invoiceDetails') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.salesOrder') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Localsale__Localsalesorder__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.invoiceNo') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Localsale__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Localsale__Customer__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyerPaymentTerm') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Localsale__Paymentstatement__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.collectionDeliveryDate') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ formatDate(Localsale__pickup_date) }}</v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.buyerPayments') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3 v-if="Payment__id == null">
                                        <v-flex xs12 lg12 md12 pl-5 py-1 class="align-center">
                                            <v-alert
                                                color="red"
                                                dense
                                                icon="warning"
                                                style="height: unset; margin-bottom: 0px;"
                                                text
                                            >{{ $t('message.paymentRecordNotFound') }}</v-alert>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row :class="Payment__id ? 'pt-3' : '' ">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">
                                            {{ $t('message.dueDate') }}
                                        </v-flex>
                                        <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row justify-space-between">
                                            <div>{{ Payment__paymentdate != '' && Payment__paymentdate != null ? formatDate(Payment__paymentdate) : $t('message.na') }}</div>
                                            <v-btn
                                                :loading="loading.updateDueDate"
                                                small
                                                outlined
                                                @click="updateDueDates(salesType)"
                                            >{{ $t('message.updateDueDate') }}
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">
                                            {{ $t('message.currentPaymentStatus') }}
                                        </v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <span>{{ Payment__title }}</span>
                                            <v-autocomplete
                                                :items="paymentStatuses"
                                                :value="Payment__paymentterm_id"
                                                dense
                                                hide-details
                                                item-text="PaymentStatus.title"
                                                item-value="PaymentStatus.id"
                                                return-object
                                                solo
                                                @change="updatePaymentStatus('Payment__paymentterm_id',$event)"
                                                v-if="false"
                                            ></v-autocomplete>
                                            <v-autocomplete
                                                :items="paymentStatuses"
                                                :placeholder="$t('message.selectNewStatus')"
                                                dense
                                                hide-details
                                                item-text="PaymentStatus.title"
                                                item-value="PaymentStatus.id"
                                                return-object
                                                solo
                                                @change="updatePaymentStatus('Payment__paymentterm_id',$event)"
                                            ></v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.dpPaid') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                :true-value="1"
                                                class="mt-2"
                                                dense
                                                hide-details
                                                v-model="Payment__is_dppaid"
                                            ></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.fullyPaid') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                :true-value="1"
                                                class="mt-2"
                                                dense
                                                hide-details
                                                v-model="Payment__fully_paid"
                                                @change="updateLocalSaleFullyPaidStatus(Payment__fully_paid)"
                                            ></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">
                                            {{ $t('message.currentPaymentStatus') }}
                                        </v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <span>{{ Payment__title }}</span>
                                        </v-flex>
                                    </v-layout>
                                    <PaymentTransactionsList :update-mode="updateMode" payment-type="Payment" document-type="Localsale" class="ml-2"></PaymentTransactionsList>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_payment')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row no-gutters>
                                        <v-col cols="12" class="pr-1" v-if="Payment__id != null">
                                            <DocumentHistory asset="payments" :asset_id="Payment__id"></DocumentHistory>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-expansion-panels
                            :value="[0,1]"
                            accordion
                            multiple
                            v-if="salesType == 'eprs'"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.eprAndPoDetails') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.eprNo') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Epr__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Epr__Supplier__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplierPaymentTerm') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ Epr__PoPaymentstatement__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.etd') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ formatDate(Epr__etddate) }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.eta') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>{{ formatDate(Epr__etadate) }}</v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.supplierPayments') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3 v-if="PoPayment__id == null">
                                        <v-flex xs12 lg12 md12 pl-5 py-1 class="align-center">
                                            <v-alert
                                                color="red"
                                                dense
                                                icon="warning"
                                                style="height: unset; margin-bottom: 0px;"
                                                text
                                            >{{ $t('message.poPaymentRecordNotFound') }}</v-alert>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                                <v-layout row :class="PoPayment__id ? 'pt-3' : '' " v-if="PoPayment__id != null">
                                    <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">
                                        {{ $t('message.dueDate') }}
                                    </v-flex>
                                    <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row justify-space-between">
                                        <div>{{ PoPayment__paymentdate != '' && PoPayment__paymentdate != null ? formatDate(PoPayment__paymentdate) : $t('message.na') }}</div>
                                        <v-btn
                                            :loading="loading.updateDueDate"
                                            small
                                            outlined
                                            @click="updateDueDates(salesType)"
                                        >{{ $t('message.updateDueDate') }}
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                                <v-layout row v-if="PoPayment__id != null && PoPayment__dprequired == 1 && false">
                                    <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.dpPaid') }}</v-flex>
                                    <v-flex xs6 lg3 md5 py-1>
                                        <v-checkbox
                                            :true-value="1"
                                            :value="1"
                                            class="mt-0 ml-0"
                                            dense
                                            hide-details="auto"
                                            v-model="PoPayment__is_dppaid"
                                            @change="updateDownPaymentPaidStatus('p', PoPayment__paymentstatement_id, $event)"
                                        ></v-checkbox>
                                    </v-flex>
                                </v-layout>
                                <v-layout row v-if="PoPayment__id != null">
                                    <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.fullyPaid') }}</v-flex>
                                    <v-flex xs6 lg3 md5 py-1>
                                        <v-checkbox
                                            :true-value="1"
                                            :value="1"
                                            class="mt-0 ml-0"
                                            dense
                                            hide-details="auto"
                                            v-model="PoPayment__fully_paid"
                                            @change="updateFullyPaidStatus('p', PoPayment__paymentstatement_id, $event)"
                                        ></v-checkbox>
                                    </v-flex>
                                </v-layout>
                                <v-layout row v-if="PoPayment__id != null">
                                    <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.currentPaymentStatus') }}</v-flex>
                                    <v-flex xs6 lg3 md5 py-1>
                                        <div class="d-flex flex-row">
                                            <v-progress-circular
                                                class="mr-2"
                                                color="grey lighten-1"
                                                indeterminate
                                                size="20"
                                                width="4"
                                                v-if="loading.poPaymentTitle"
                                            ></v-progress-circular>
                                            <span>{{ PoPayment__title }}</span>
                                        </div>
                                        <v-autocomplete
                                            :items="paymentStatuses"
                                            :value="PoPayment__paymentterm_id"
                                            dense
                                            hide-details
                                            item-text="PaymentStatus.title"
                                            item-value="PaymentStatus.id"
                                            return-object
                                            solo
                                            @change="updatePaymentStatus('PoPayment__paymentterm_id',$event)"
                                            v-if="false"
                                        ></v-autocomplete>
                                        <v-autocomplete
                                            :items="paymentStatuses"
                                            :placeholder="$t('message.selectNewStatus')"
                                            dense
                                            hide-details
                                            item-text="PaymentStatus.title"
                                            item-value="PaymentStatus.id"
                                            return-object
                                            solo
                                            @change="updatePaymentStatus('PoPayment__paymentterm_id',$event)"
                                            v-if="false"
                                        ></v-autocomplete>
                                    </v-flex>
                                </v-layout>
                                <PaymentTransactionsList
                                    :update-mode="updateMode"
                                    payment-type="PoPayment"
                                    document-type="Epr"
                                    class="ml-2" v-if="PoPayment__id != null"
                                    @dp-paid-set="updateDownPaymentPaidStatusV2('p', PoPayment__paymentstatement_id, 1)"
                                    @dp-paid-unset="updateDownPaymentPaidStatusV2('p', PoPayment__paymentstatement_id, null)"
                                ></PaymentTransactionsList>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_payment')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row no-gutters>
                                        <v-col cols="12" class="pr-1" v-if="PoPayment__id != null">
                                            <DocumentHistory asset="payments" :asset_id="PoPayment__id"></DocumentHistory>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
                                    <v-btn
                                        :loading="loading.save"
                                        class="ml-3"
                                        color="info"
                                        small
                                        v-if="updateMode"
                                        @click="saveDocument()"
                                    >{{ $t('message.saveChanges') }}
                                    </v-btn>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import PaymentAlert from "Components/Appic/PaymentAlert";
    import {mapFields} from "vuex-map-fields";
    import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
    import {formatDate, numberFormat} from "Helpers/helpers";
    import PaymentTransactionsList from "Components/Appic/PaymentTransactionsList";
    import {api} from "Api";
    // import DocumentHistory from "../../../components/Appic/DocumentHistory";
    // import NotificationsLog from "Components/Appic/NotificationsLog";

    const DocumentHistory = () => import("../../../components/Appic/DocumentHistory");
    const NotificationsLog = () => import("Components/Appic/NotificationsLog");

    export default {
        name: "AddPayment",
        components: {NotificationsLog, DocumentHistory, PaymentTransactionsList, PaymentAlert},
        props: ['documentId','salesType'],
        title: '',
        data() {
            return {
                historyKey: '',
                loader: false,
                loading: {
                    save: false,
                    updateDueDate: false,
                    paymentTitle: false,
                    poPaymentTitle: false
                },
                panels: {
                    contract: [0,2,3],
                    whsale: [0,1]
                },
                paymentStatuses: [],
                uiVersion: '2.0'
            }
        },
        computed: {
            statePrefix() {
                if (this.updateMode) {
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('payment',{
                Contract__etadate: 'Contract.etadate',
                Contract__etddate: 'Contract.etddate',
                Contract__etsdate: 'Contract.etsdate',
                Contract__id: 'Contract.id',
                Contract__partial_no: 'Contract.partial_no',
                Contract__revision_no: 'Contract.revision_no',
                Contract__salestype_id: 'Contract.salestype_id',
                Contract__title: 'Contract.title',
                Contract__version: 'Contract.version',
                Contract__Customer__title: 'Contract.Customer.title',
                Contract__Invoice__invno: 'Contract.Invoice.invno',
                Contract__Paymentstatement__title: 'Contract.Paymentstatement.title',
                Contract__PoPaymentstatement__title: 'Contract.PoPaymentstatement.title',
                Contract__Purchaseorder__title: 'Contract.Purchaseorder.title',
                Contract__Supplier__title: 'Contract.Supplier.title',

                Epr__id: 'Epr.id',
                Epr__etadate: 'Epr.etadate',
                Epr__etddate: 'Epr.etddate',
                Epr__title: 'Epr.title',
                Epr__Customer__title: 'Epr.Customer.title',
                Epr__PoPaymentstatement__title: 'Epr.PoPaymentstatement.title',
                Epr__Supplier__title: 'Epr.Supplier.title',

                Localsale__id: 'Localsale.id',
                Localsale__pickup_date: 'Localsale.pickup_date',
                Localsale__title: 'Localsale.title',
                Localsale__Customer__title: 'Localsale.Customer.title',
                Localsale__Localsalesorder__title: 'Localsale.Localsalesorder.title',
                Localsale__Paymentstatement__title: 'Localsale.Paymentstatement.title',

                Payment__id: 'Payment.id',
                Payment__dprequired: 'Payment.dprequired',
                Payment__fully_paid: 'Payment.fully_paid',
                Payment__is_dppaid: 'Payment.is_dppaid',
                Payment__paymentdate: 'Payment.paymentdate',
                Payment__paymentstatement_id: 'Payment.paymentstatement_id',
                Payment__paymentterm_id: 'Payment.paymentterm_id',
                Payment__title: 'Payment.title',
                PoPayment__id: 'PoPayment.id',
                PoPayment__dprequired: 'PoPayment.dprequired',
                PoPayment__fully_paid: 'PoPayment.fully_paid',
                PoPayment__is_dppaid: 'PoPayment.is_dppaid',
                PoPayment__paymentdate: 'PoPayment.paymentdate',
                PoPayment__paymentstatement_id: 'PoPayment.paymentstatement_id',
                PoPayment__paymentterm_id: 'PoPayment.paymentterm_id',
                PoPayment__title: 'PoPayment.title',
            },'statePrefix'),
            ...mapFields('payment',{
                newPaymentSaved: 'newPaymentSaved',
                newPaymentUnsaved: 'newPaymentUnsaved',
                updatedPaymentUnsaved: 'updatedPaymentUnsaved',
                updatedPaymentSaved: 'updatedPaymentSaved'
            }),
            ...mapGetters([
                'currencies'
            ]),
            addMode: function () {
                return this.$route.matched.some(({name}) => name === 'add_payments')
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_payments')
            },
        },
        methods: {
            ...mapActions('payment',{
                getDocumentToUpdateById: 'getDocumentToUpdateById',
                getPaymentStatuses: 'getPaymentStatuses',
                getCleanedPaymentStatuses: 'getCleanedPaymentStatuses',
                updatePayments: 'updatePayments',
                updatePaymentDueDates: 'updatePaymentDueDates'
            }),
            ...mapActions('paymenttransaction',{
                getTransactionsToUpdateByDocumentById: 'getTransactionsToUpdateByDocumentById',
                resetTransactions: 'resetTransactions'
            }),
            formatDate,
            getCurrentPaymentStatus(paymentStatusId) {
                let status = ''
                let paymentStatus = this.paymentStatuses.find(s => s.PaymentStatus.id == paymentStatusId)
                if(paymentStatus){
                    status = paymentStatus.PaymentStatus.title
                }
                return status
            },
            loadDocumentById (payload) {
                this.loader = true
                this.getDocumentToUpdateById(payload)
                    .then(() => {
                        let resetPayload = {
                            prefix: this.statePrefix
                        }
                        this.resetTransactions(resetPayload)
                        if(this.Contract__salestype_id == 3 || this.Epr__id != null){
                            if(this.PoPayment__id != null) {
                                let payloadPoPayment = {
                                    prefix: this.statePrefix,
                                    paymentId: this.PoPayment__id,
                                    paymentType: 'PoPayment'
                                }
                                this.getTransactionsToUpdateByDocumentById(payloadPoPayment)
                                    .then(() => {
                                        this.loader = false
                                        this.updatedPaymentUnsaved = false
                                    })
                                    .catch(() => {
                                        this.loader = false
                                        this.updatedPaymentUnsaved = false
                                    })
                            } else {
                                this.loader = false
                                this.updatedPaymentUnsaved = false
                            }
                        } else {
                            let payloadPayment = {
                                prefix: this.statePrefix,
                                paymentId: this.Payment__id,
                                paymentType: 'Payment'
                            }
                            if(this.Payment__id != null) {
                                this.getTransactionsToUpdateByDocumentById(payloadPayment)
                                    .then(() => {
                                        if (this.Contract__salestype_id != 2) {
                                            if (this.PoPayment__id != null) {
                                                let payloadPoPayment = {
                                                    prefix: this.statePrefix,
                                                    paymentId: this.PoPayment__id,
                                                    paymentType: 'PoPayment'
                                                }
                                                this.getTransactionsToUpdateByDocumentById(payloadPoPayment)
                                                    .then(() => {
                                                        this.loader = false
                                                        this.updatedPaymentUnsaved = false
                                                    })
                                                    .catch(() => {
                                                        this.loader = false
                                                        this.updatedPaymentUnsaved = false
                                                    })
                                            } else {
                                                this.loader = false
                                                this.updatedPaymentUnsaved = false
                                            }
                                        } else {
                                            this.loader = false
                                            this.updatedPaymentUnsaved = false
                                        }
                                    })
                            } else {
                                //try to load PO Payments only
                                if (this.Contract__salestype_id != 2) {
                                    if (this.PoPayment__id != null) {
                                        let payloadPoPayment = {
                                            prefix: this.statePrefix,
                                            paymentId: this.PoPayment__id,
                                            paymentType: 'PoPayment'
                                        }
                                        this.getTransactionsToUpdateByDocumentById(payloadPoPayment)
                                            .then(() => {
                                                this.loader = false
                                                this.updatedPaymentUnsaved = false
                                            })
                                            .catch(() => {
                                                this.loader = false
                                                this.updatedPaymentUnsaved = false
                                            })
                                    } else {
                                        this.loader = false
                                        this.updatedPaymentUnsaved = false
                                    }
                                }
                                this.loader = false
                                this.updatedPaymentUnsaved = false
                            }
                        }
                    })
                    .catch(() => {
                        this.loader = false
                        this.updatedPaymentUnsaved = false
                    })
            },
            saveDocument () {
                this.loading.save = true
                let payload = {
                    documentId: this.documentId,
                    salesType: this.salesType
                }
                this.updatePayments(payload)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.paymentsUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            if(this.Contract__salestype_id != 3 && this.Epr__id == null) {
                                let payloadPayment = {
                                    prefix: this.statePrefix,
                                    paymentId: this.Payment__id,
                                    paymentType: 'Payment'
                                }
                                this.getTransactionsToUpdateByDocumentById(payloadPayment)
                                    .then(() => {
                                        if (this.Contract__salestype_id != 2 && this.salesType == 'contracts') {
                                            if(this.PoPayment__id != null) {
                                                let payloadPoPayment = {
                                                    prefix: this.statePrefix,
                                                    paymentId: this.PoPayment__id,
                                                    paymentType: 'PoPayment'
                                                }
                                                this.getTransactionsToUpdateByDocumentById(payloadPoPayment)
                                                    .then(() => {
                                                        this.loading.save = false
                                                        this.updatedPaymentUnsaved = false
                                                    })
                                                    .catch(() => {
                                                        this.loading.save = false
                                                        this.updatedPaymentUnsaved = false
                                                    })
                                            } else {
                                                this.loader = false
                                                this.loading.save = false
                                                this.updatedPaymentUnsaved = false
                                            }
                                        } else {
                                            this.loading.save = false
                                            this.updatedPaymentUnsaved = false
                                        }
                                    })
                                    .catch(() => {
                                        this.loading.save = false
                                        this.updatedPaymentUnsaved = false
                                    })
                            } else {
                                let payloadPoPayment = {
                                    prefix: this.statePrefix,
                                    paymentId: this.PoPayment__id,
                                    paymentType: 'PoPayment'
                                }
                                if(this.PoPayment__id != null) {
                                    this.getTransactionsToUpdateByDocumentById(payloadPoPayment)
                                        .then(() => {
                                            this.loading.save = false
                                            this.updatedPaymentUnsaved = false
                                        })
                                        .catch(() => {
                                            this.loading.save = false
                                            this.updatedPaymentUnsaved = false
                                        })
                                } else {
                                    this.loader = false
                                    this.updatedPaymentUnsaved = false
                                }
                            }
                        } else {
                            this.$toast.error(this.$t('message.errors.paymentsNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.loading.save = false
                        this.updatedPaymentUnsaved = false
                    })
            },
            updateDueDates () {
                this.loading.updateDueDate = true
                let payload = {
                    documentId: this.documentId,
                    prefix: this.statePrefix,
                    salesType: this.salesType,
                    paymentIds: [ this.Payment__id, this.PoPayment__id]
                }
                this.updatePaymentDueDates( payload )
                    .then(() => {
                        this.$toast.success(this.$t('message.successes.paymentDueDateUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.loading.updateDueDate = false
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.paymentDueDateNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.updateDueDate = false
                    })
            },
            updateDownPaymentPaidStatus(model, paymentStatementId, status) {
                let promise = new Promise((resolve, reject) => {
                    api
                        .get('/paymentterms/' + paymentStatementId)
                        .then(response => {
                            resolve(response.data.data[0])
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                let fullyPaid = false
                switch(model) {
                    case 'c':
                        this.loading.paymentTitle = true
                        if(this.Payment__fully_paid == 1){
                            fullyPaid = true
                        }
                        break;
                    case 'p':
                        this.loading.poPaymentTitle = true
                        if(this.PoPayment__fully_paid == 1){
                            fullyPaid = true
                        }
                        break;
                }
                // console.log(this.PoPayment__fully_paid, status)
                if(status != true){
                    //revert to unpaid OTS term
                    if(fullyPaid == false) {
                        promise
                            .then((paymentTerm) => {
                                let otsTerm = this.paymentStatuses.find(ps => ps.PaymentStatus.id == paymentTerm.Paymentterm.paymentterm_id)
                                switch (model) {
                                    case 'c':
                                        this.Payment__paymentterm_id = otsTerm.PaymentStatus.id
                                        this.Payment__title = otsTerm.PaymentStatus.title
                                        this.loading.paymentTitle = false
                                        break;
                                    case 'p':
                                        this.PoPayment__paymentterm_id = otsTerm.PaymentStatus.id
                                        this.PoPayment__title = otsTerm.PaymentStatus.title
                                        this.loading.poPaymentTitle = false
                                        break;
                                }
                            })
                    } else {
                        switch (model) {
                            case 'c':
                                this.loading.paymentTitle = false
                                break;
                            case 'p':
                                this.loading.poPaymentTitle = false
                                break;
                        }
                    }
                } else {
                    if(fullyPaid == false) {
                        promise
                            .then((paymentTerm) => {
                                let otsTerm = this.paymentStatuses.find(ps => ps.PaymentStatus.id == paymentTerm.Paymentterm.dppaid_id)
                                switch (model) {
                                    case 'c':
                                        this.Payment__paymentterm_id = otsTerm.PaymentStatus.id
                                        this.Payment__title = otsTerm.PaymentStatus.title
                                        this.loading.paymentTitle = false
                                        break;
                                    case 'p':
                                        this.PoPayment__paymentterm_id = otsTerm.PaymentStatus.id
                                        this.PoPayment__title = otsTerm.PaymentStatus.title
                                        this.loading.poPaymentTitle = false
                                        break;
                                }
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.paymentStatusNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                switch (model) {
                                    case 'c':
                                        this.loading.paymentTitle = false
                                        break;
                                    case 'p':
                                        this.loading.poPaymentTitle = false
                                        break;
                                }
                            })
                    } else {
                        switch (model) {
                            case 'c':
                                this.loading.paymentTitle = false
                                break;
                            case 'p':
                                this.loading.poPaymentTitle = false
                                break;
                        }
                    }
                }
            },
            updateDownPaymentPaidStatusV2(model, paymentStatementId, status) {
                console.log(status)
                if(model == 'c') {
                    this.Payment__is_dppaid = status
                } else if(model == 'p') {
                    this.PoPayment__is_dppaid = status
                }
                let promise = new Promise((resolve, reject) => {
                    api
                        .get('/paymentterms/' + paymentStatementId)
                        .then(response => {
                            resolve(response.data.data[0])
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                let fullyPaid = false
                switch(model) {
                    case 'c':
                        this.loading.paymentTitle = true
                        if(this.Payment__fully_paid == 1){
                            fullyPaid = true
                        }
                        break;
                    case 'p':
                        this.loading.poPaymentTitle = true
                        if(this.PoPayment__fully_paid == 1){
                            fullyPaid = true
                        }
                        break;
                }
                if(status != true){
                    //revert to unpaid OTS term
                    if(fullyPaid == false) {
                        promise
                            .then((paymentTerm) => {
                                let otsTerm = this.paymentStatuses.find(ps => ps.PaymentStatus.id == paymentTerm.Paymentterm.paymentterm_id)
                                switch (model) {
                                    case 'c':
                                        this.Payment__paymentterm_id = otsTerm.PaymentStatus.id
                                        this.Payment__title = otsTerm.PaymentStatus.title
                                        this.loading.paymentTitle = false
                                        break;
                                    case 'p':
                                        this.PoPayment__paymentterm_id = otsTerm.PaymentStatus.id
                                        this.PoPayment__title = otsTerm.PaymentStatus.title
                                        this.loading.poPaymentTitle = false
                                        break;
                                }
                            })
                    } else {
                        switch (model) {
                            case 'c':
                                this.loading.paymentTitle = false
                                break;
                            case 'p':
                                this.loading.poPaymentTitle = false
                                break;
                        }
                    }
                } else {
                    if(fullyPaid == false) {
                        promise
                            .then((paymentTerm) => {
                                let otsTerm = this.paymentStatuses.find(ps => ps.PaymentStatus.id == paymentTerm.Paymentterm.dppaid_id)
                                switch (model) {
                                    case 'c':
                                        this.Payment__paymentterm_id = otsTerm.PaymentStatus.id
                                        this.Payment__title = otsTerm.PaymentStatus.title
                                        this.loading.paymentTitle = false
                                        break;
                                    case 'p':
                                        this.PoPayment__paymentterm_id = otsTerm.PaymentStatus.id
                                        this.PoPayment__title = otsTerm.PaymentStatus.title
                                        this.loading.poPaymentTitle = false
                                        break;
                                }
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.paymentStatusNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                switch (model) {
                                    case 'c':
                                        this.loading.paymentTitle = false
                                        break;
                                    case 'p':
                                        this.loading.poPaymentTitle = false
                                        break;
                                }
                            })
                    } else {
                        switch (model) {
                            case 'c':
                                this.loading.paymentTitle = false
                                break;
                            case 'p':
                                this.loading.poPaymentTitle = false
                                break;
                        }
                    }
                }
            },
            updateFullyPaidStatus(model, paymentStatementId, status) {
                let promise = new Promise((resolve, reject) => {
                    api
                        .get('/paymentterms/' + paymentStatementId)
                        .then(response => {
                            resolve(response.data.data[0])
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                switch(model) {
                    case 'c':
                        this.loading.paymentTitle = true
                        break;
                    case 'p':
                        this.loading.poPaymentTitle = true
                        break;
                }
                if(status != true){
                    //check if DP paid
                    let otsTerm = null
                    promise
                        .then((paymentTerm) =>{
                            switch(model){
                                case 'c':
                                    if(this.Payment__is_dppaid == 1){
                                        //revert to DP paid OTS term
                                        otsTerm = this.paymentStatuses.find(ps => ps.PaymentStatus.id == paymentTerm.Paymentterm.dppaid_id)
                                    } else {
                                        //revert to unpaid OTS term
                                        otsTerm = this.paymentStatuses.find(ps => ps.PaymentStatus.id == paymentTerm.Paymentterm.paymentterm_id)
                                    }
                                    this.Payment__paymentterm_id = otsTerm.PaymentStatus.id
                                    this.Payment__title = otsTerm.PaymentStatus.title
                                    this.loading.paymentTitle = false
                                    break;
                                case 'p':
                                    if(this.PoPayment__is_dppaid == 1){
                                        //revert to DP paid OTS term
                                        otsTerm = this.paymentStatuses.find(ps => ps.PaymentStatus.id == paymentTerm.Paymentterm.dppaid_id)
                                    } else {
                                        //revert to unpaid OTS term
                                        otsTerm = this.paymentStatuses.find(ps => ps.PaymentStatus.id == paymentTerm.Paymentterm.paymentterm_id)
                                    }
                                    this.PoPayment__paymentterm_id = otsTerm.PaymentStatus.id
                                    this.PoPayment__title = otsTerm.PaymentStatus.title
                                    this.loading.poPaymentTitle = false
                                    break;
                            }
                        })
                        .catch(() =>{
                            this.$toast.error(this.$t('message.errors.paymentStatusNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            switch(model) {
                                case 'c':
                                    this.loading.paymentTitle = false
                                    break;
                                case 'p':
                                    this.loading.poPaymentTitle = false
                                    break;
                            }
                        })
                } else {
                    promise
                        .then((paymentTerm) =>{
                            let otsTerm = this.paymentStatuses.find(ps => ps.PaymentStatus.id == paymentTerm.Paymentterm.fullypaid_id)
                            switch (model) {
                                case 'c':
                                    this.Payment__paymentterm_id = otsTerm.PaymentStatus.id
                                    this.Payment__title = otsTerm.PaymentStatus.title
                                    this.loading.paymentTitle = false
                                    break;
                                case 'p':
                                    this.PoPayment__paymentterm_id = otsTerm.PaymentStatus.id
                                    this.PoPayment__title = otsTerm.PaymentStatus.title
                                    this.loading.poPaymentTitle = false
                                    break;
                            }
                        })
                        .catch(() =>{
                            this.$toast.error(this.$t('message.errors.paymentStatusNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            switch(model) {
                                case 'c':
                                    this.loading.paymentTitle = false
                                    break;
                                case 'p':
                                    this.loading.poPaymentTitle = false
                                    break;
                            }
                        })
                }
            },
            updateLocalSaleFullyPaidStatus(status) {
                if(status == 1){
                    this.Payment__title = 'TT OK'
                } else {
                    this.Payment__title = null;
                }
            },
            updateHistoryKey() {
                this.historyKey = Math.random() * (1000 - 1) + 1
            },
            updatePaymentStatus(field, object) {
                if(field == 'Payment__paymentterm_id'){
                    this.Payment__paymentterm_id = object.PaymentStatus.id
                    this.Payment__title = object.PaymentStatus.title
                } else if(field == 'PoPayment__paymentterm_id'){
                    this.PoPayment__paymentterm_id = object.PaymentStatus.id
                    this.PoPayment__title = object.PaymentStatus.title
                }
            }
        },
        watch: {},
        created () {
            this.getCleanedPaymentStatuses()
                .then( data => {
                    this.paymentStatuses = data
                })
            if(this.$route.matched.some(({name}) => name === 'update_payments')) {
                let payload = {
                    documentId: this.documentId,
                    salesType: this.salesType
                }
                this.loadDocumentById(payload)
            } else if(this.$route.matched.some(({name}) => name === 'add_payments')){
                this.newPaymentUnsaved = true;
            }
        },
        mounted () {
            this.$store.watch(
                function (state) {
                    return state.appic.payment.update.Payment
                },
                () => {
                    this.updatedPaymentUnsaved = true
                },
                {deep: true}
            )
            this.$store.watch(
                function (state) {
                    return state.appic.payment.current.Payment
                },
                () => {
                    this.newPaymentUnsaved = true
                },
                {deep: true}
            )
            if(this.$route.matched.some(({name}) => name === 'update_payments')){
                setInterval(() => {
                    if(this.salesType == 'contracts') {
                        this.$title = this.$t('message.titles.payments') + ": " + (this.Contract__version != null ? [this.Contract__title, this.Contract__revision_no, this.Contract__partial_no].filter(Boolean).join('-') : this.Contract__title)
                    } else if(this.salesType == 'localsales') {
                        this.$title = this.$t('message.titles.payments') + ": " + this.Localsale__title
                    } else if(this.salesType == 'eprs') {
                        this.$title = this.$t('message.titles.payments') + ": " + this.Epr__title
                    }
                }, 2000)
            } else {
                this.$title =  this.$t('message.titles.newPayments')
            }
        }
    }
</script>

<style>
.force-text-left .v-text-field__slot input {
    text-align: left !important;
}

.v-dialog {
    min-width: 200px !important;
}

.wrap-text {
    white-space: normal;
}

.font-xs {
    font-size: small;
}
</style>